import './index.scss';
import '@cloudscape-design/global-styles/index.css';

import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';
import { applyTheme } from '@cloudscape-design/components/theming';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

import App from './App';
import RequireAuth from './components/RequireAuth';
import EditCADPage from './pages/EditCAD';
import EditFeedbackPage from './pages/EditFeedback';
import EditPricingPage from './pages/EditPricing';
import HMI from './pages/HMI';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import OrdersPage from './pages/Orders';
import ActiveOrdersPage from './pages/Orders/active';
import CreateOrderPage from './pages/Orders/create';
import ShippedOrdersPage from './pages/Orders/shipped';
import UpdateOrderPage from './pages/Orders/update';
import PartPage, { PartsListPage } from './pages/Part';
import SummaryPage, { ProcessDataPage } from './pages/ProcessData';
import TravelerPage from './pages/Travelers';
import UserPage from './pages/User';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messageSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const theme = {
  tokens: {
    fontFamilyBase: 'Roboto',
    colorTextAccent: '#4FAFC3',
  },
  contexts: {
    'top-navigation': {
      tokens: {
        colorBackgroundContainerContent: '#000000',
        colorTextAccent: '#4FAFC3',
      },
    },
    header: {
      tokens: {
        colorBackgroundLayoutMain: '#000000',
      },
    },
  },
};
applyTheme({ theme });

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <RequireAuth><HomePage /></RequireAuth>,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'user/:userID',
        element: <RequireAuth><UserPage /></RequireAuth>,
      },
      {
        path: 'parts',
        element: <RequireAuth><PartsListPage /></RequireAuth>,
      },
      {
        path: 'part/:partId',
        element: <RequireAuth><PartPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/cad',
        element: <RequireAuth><EditCADPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/feedback',
        element: <RequireAuth><EditFeedbackPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/pricing',
        element: <RequireAuth><EditPricingPage /></RequireAuth>,
      },
      {
        path: 'orders',
        exact: true,
        element: <RequireAuth><OrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/active',
        exact: true,
        element: <RequireAuth><ActiveOrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/shipped',
        exact: true,
        element: <RequireAuth><ShippedOrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/create',
        exact: true,
        element: <RequireAuth><CreateOrderPage /></RequireAuth>,
      },
      {
        path: 'orders/update/:orderId',
        exact: true,
        element: <RequireAuth><UpdateOrderPage /></RequireAuth>,
      },
      {
        path: 'process-data/forming',
        element: <RequireAuth><SummaryPage processType="forming" /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/forming/:sessionId',
        element: <RequireAuth><ProcessDataPage processType="forming" /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/bonding',
        element: <RequireAuth><SummaryPage processType="bonding" /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/bonding/:sessionId',
        element: <RequireAuth><ProcessDataPage processType="bonding" /></RequireAuth>,
        exact: true,
      },
      {
        path: '/live/:pressName',
        element: <RequireAuth><HMI /></RequireAuth>,
        exact: true,
      },
      {
        path: '/travelers/:travelerId',
        element: <RequireAuth><TravelerPage /></RequireAuth>,
        exact: true,
      },
    ],
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <I18nProvider locale="en" messages={[enMessages]}>
        <RouterProvider router={router} />
      </I18nProvider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
