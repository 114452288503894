import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  collection,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import {
  FieldArray,
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useNotifications } from '../../features/notifications';
import LineItemsTable from './lineItemsTable';
import PurchaseOrderUploader from './purchaseOrderUploader';

const orderValidationSchema = Yup.object().shape({
  customer: Yup.string().required('Customer is required'),
  name: Yup.string(),
  orderDate: Yup.string()
    .required('Order Date is required')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Order Date must be in the format YYYY-MM-DD'),
  purchaseOrder: Yup.array().of(
    Yup.object().shape({
      downloadURL: Yup.string().url('Must be a valid URL').required('Download URL is required'),
      lastModifiedTS: Yup.number().required('Last Modified Timestamp is required'),
      name: Yup.string().required('Name is required'),
      ref: Yup.string().required('Reference URL is required'),
      type: Yup.string().required('Type is required'),
    }),
  ),
  shipments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Shipment Name is required'),
      address: Yup.string().required('Address is required'),
      targetShipDate: Yup.string()
        .required('Target Ship Date is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Target Ship Date must be in the format YYYY-MM-DD'),
      shippingMethod: Yup.string(),
      shipDate: Yup.string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Ship Date must be in the format YYYY-MM-DD'),
      trackingLink: Yup.string().url('Must be a valid URL'),
      lineItems: Yup.array().of(
        Yup.object().shape({
          bondedGoal: Yup.number().required('Bonded Goal is required'),
          bonding: Yup.string().required('Bonding type is required'),
          connectorizedGoal: Yup.number().required('Connectorized Goal is required'),
          moldedGoal: Yup.number().required('Molded Goal is required'),
          notes: Yup.string(),
          postmachinedGoal: Yup.number().required('Postmachined Goal is required'),
          projectId: Yup.string().required('Project ID is required'),
          quantity: Yup.number().positive('Quantity must be a positive number').required('Quantity is required'),
        }),
      ).min(1, 'At least one line item is required'),
      amount: Yup.string()
        .required('Amount is required')
        .matches(/^\$\d{1,3}(,\d{3})*(\.\d{2})?$/, 'Amount must be in the format $0.00'),
    }),
  ).min(1, 'At least one shipment is required'),
  status: Yup.object().shape({
    label: Yup.string().required('Status label is required'),
    value: Yup.string().required('Status value is required'),
  }).required('Status is required'),
  stripeId: Yup.string(),
});

const defaultLineItem = {
  projectId: '',
  quantity: null,
  bonding: '',
  notes: '',
  moldedGoal: null,
  postmachinedGoal: null,
  bondedGoal: null,
  connectorizedGoal: null,
};

const defaultShipment = {
  name: '',
  address: '',
  targetShipDate: '',
  shippingMethod: '',
  shipDate: '',
  trackingLink: '',
  lineItems: [
    { ...defaultLineItem },
  ],
  amount: '',
};

export default function ManageOrder({ initData, isEditing }) {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  // Add a new document with a generated id
  const newOrderRef = useMemo(() => {
    if (isEditing) {
      return doc(getFirestore(), 'orders', initData.id);
    }
    return doc(collection(getFirestore(), 'orders'));
  }, [initData.id, isEditing]);

  const initialValues = useMemo(() => ({
    ...initData,
    status: { label: initData.status, value: initData.status },
  }), [initData]);

  const handleSubmitOrder = useCallback(async (values) => {
    const castValue = orderValidationSchema.cast(values);
    try {
      await setDoc(newOrderRef, {
        ...castValue,
        status: castValue.status?.value || 'Not started',
      });
      // redirect to the order detail page
      navigate('/orders');
    } catch (error) {
      addNotification({
        type: 'error',
        dismissible: true,
        content: `Failed to create order. ${error.message}`,
        id: 'create-order-error',
      });
    }
  }, [addNotification, navigate, newOrderRef]);

  const getLastSixUppercase = useCallback((str) => {
    if (str && typeof str === 'string') {
      return str.slice(-6).toUpperCase();
    }
    return ''; // or handle it as needed if the string doesn't exist
  }, []);

  const handleStripeIdChange = useCallback((stripeId, values, setFieldValue) => {
    const lastSixUppercase = getLastSixUppercase(stripeId);
    setFieldValue('stripeId', stripeId);
    setFieldValue('name', lastSixUppercase);
    values.shipments.forEach((shipment, index) => {
      setFieldValue(`shipments[${index}].name`, `${lastSixUppercase}.${index + 1}`);
    });
  }, [getLastSixUppercase]);

  const formatCurrency = useCallback((value) => {
    if (value === '') return '';
    const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    if (Number.isNaN(numberValue)) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(numberValue);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={orderValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitOrder(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        dirty,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <SpaceBetween direction="vertical" size="s">
            {/* Order Basic Info */}
            <Container>
              <ColumnLayout columns={2}>
                <FormField
                  label="Customer"
                  errorText={errors.customer}
                >
                  <Input
                    name="customer"
                    value={values.customer}
                    onChange={({ detail }) => { setFieldValue('customer', detail.value); }}
                  />
                </FormField>

                <FormField
                  label="Name (Optional)"
                  errorText={errors.name}
                >
                  <Input
                    name="name"
                    value={values.name}
                    onChange={({ detail }) => { setFieldValue('name', detail.value); }}
                  />
                </FormField>

                <FormField
                  label="Order Date"
                  errorText={errors.orderDate}
                >
                  <Input
                    name="orderDate"
                    value={values.orderDate}
                    onChange={({ detail }) => { setFieldValue('orderDate', detail.value); }}
                  />
                </FormField>

                <FormField
                  label="Status"
                  errorText={errors.status}
                >
                  <Select
                    selectedOption={values.status}
                    onChange={({ detail }) => { setFieldValue('status', detail.selectedOption); }}
                    options={[
                      { label: 'Not started', value: 'Not started' },
                      { label: 'In progress', value: 'In progress' },
                      { label: 'Shipped', value: 'Shipped' },
                      { label: 'Archived', value: 'Archived' },
                    ]}
                  />
                </FormField>

                <FormField
                  label="Stripe Id"
                  errorText={errors.stripeId}
                >
                  <Input
                    name="stripeId"
                    value={values.stripeId}
                    onChange={({ detail }) => {
                      handleStripeIdChange(detail.value, values, setFieldValue);
                    }}
                  />
                </FormField>
              </ColumnLayout>
            </Container>

            {/* Purchase Orders */}
            <PurchaseOrderUploader orderId={newOrderRef.id} />

            {/* Shipments */}
            <FieldArray name="shipments">
              {({ remove, push }) => (
                <SpaceBetween direction="vertical" size="s">
                  {values.shipments.map((shipment, index) => (
                    <Container
                      // eslint-disable-next-line react/no-array-index-key
                      key={`shipment_${index}`}
                      header={(
                        <Header
                          actions={index !== 0 && (
                            <Button
                              type="button"
                              onClick={() => remove(index)}
                            >
                              Delete Shipment
                            </Button>
                          )}
                        >
                          {`Shipment ${index + 1}`}
                        </Header>
                      )}
                    >
                      <SpaceBetween direction="vertical" size="s">
                        {/* Shipment basic Info */}
                        <ColumnLayout columns={2}>
                          <FormField
                            label="Name"
                            errorText={errors?.shipments?.[index]?.name}
                          >
                            <Input
                              name={`shipments[${index}].name`}
                              value={values.shipments[index]?.name}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].name`, detail.value); }}
                            />
                          </FormField>
                          <FormField
                            label="Target Ship Date"
                            errorText={errors?.shipments?.[index]?.targetShipDate}
                          >
                            <Input
                              name={`shipments[${index}].targetShipDate`}
                              value={values.shipments[index]?.targetShipDate}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].targetShipDate`, detail.value); }}
                            />
                          </FormField>
                          <FormField
                            label="Address"
                            errorText={errors?.shipments?.[index]?.address}
                          >
                            <Textarea
                              name={`shipments[${index}].address`}
                              value={values.shipments[index]?.address}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].address`, detail.value); }}
                            />
                          </FormField>
                          <FormField
                            label="Amount"
                            errorText={errors?.shipments?.[index]?.amount}
                          >
                            <Input
                              name={`shipments[${index}].amount`}
                              value={values.shipments[index].amount}
                              onChange={({ detail }) => { setFieldValue(`shipments[${index}].amount`, detail.value); }}
                              onBlur={() => { setFieldValue(`shipments[${index}].amount`, formatCurrency(values.shipments[index].amount)); }}
                            />
                          </FormField>

                          {isEditing && (
                            <>
                              <FormField
                                label="Shipping Method"
                                errorText={errors?.shipments?.[index]?.shippingMethod}
                              >
                                <Input
                                  name={`shipments[${index}].shippingMethod`}
                                  value={values.shipments[index]?.shippingMethod}
                                  onChange={({ detail }) => { setFieldValue(`shipments[${index}].shippingMethod`, detail.value); }}
                                />
                              </FormField>
                              <FormField
                                label="Ship Date"
                                errorText={errors?.shipments?.[index]?.shipDate}
                              >
                                <Input
                                  name={`shipments[${index}].shipDate`}
                                  value={values.shipments[index]?.shipDate}
                                  onChange={({ detail }) => { setFieldValue(`shipments[${index}].shipDate`, detail.value); }}
                                />
                              </FormField>
                              <FormField
                                label="Tracking Link"
                                errorText={errors?.shipments?.[index]?.trackingLink}
                              >
                                <Input
                                  name={`shipments[${index}].trackingLink`}
                                  value={values.shipments[index]?.trackingLink}
                                  onChange={({ detail }) => { setFieldValue(`shipments[${index}].trackingLink`, detail.value); }}
                                />
                              </FormField>
                            </>
                          )}
                        </ColumnLayout>

                        {/* LineItems */}
                        <LineItemsTable shipmentIndex={index} defaultLineItem={defaultLineItem} />
                      </SpaceBetween>
                    </Container>
                  ))}
                  <Button
                    type="button"
                    onClick={() => push({ ...defaultShipment })}
                  >
                    Add Shipment
                  </Button>
                </SpaceBetween>
              )}
            </FieldArray>

            {/* Submit Button */}
            <Box padding={{ vertical: 's' }} textAlign="right">
              <Button type="submit" variant="primary" disabled={isSubmitting || !dirty}>
                {isEditing ? 'Update Order' : 'Create Order'}
              </Button>
            </Box>
          </SpaceBetween>
        </Form>
      )}
    </Formik>
  );
}
ManageOrder.defaultProps = {
  initData: {
    id: null,
    customer: '',
    name: '',
    orderDate: '',
    status: 'Not started',
    stripeId: '',
    purchaseOrder: [],
    shipments: [{ ...defaultShipment }],
  },
  isEditing: false,
};
ManageOrder.propTypes = {
  initData: PropTypes.shape({
    id: PropTypes.string,
    customer: PropTypes.string,
    name: PropTypes.string,
    orderDate: PropTypes.string,
    status: PropTypes.string,
    stripeId: PropTypes.string,
    purchaseOrder: PropTypes.arrayOf(PropTypes.shape({
      downloadURL: PropTypes.string,
      lastModifiedTS: PropTypes.number,
      name: PropTypes.string,
      ref: PropTypes.string,
      type: PropTypes.string,
    })),
    shipments: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      targetShipDate: PropTypes.string,
      shippingMethod: PropTypes.string,
      shipDate: PropTypes.string,
      trackingLink: PropTypes.string,
      lineItems: PropTypes.arrayOf(PropTypes.shape({
        projectId: PropTypes.string,
        quantity: PropTypes.number,
        bonding: PropTypes.string,
        notes: PropTypes.string,
        moldedGoal: PropTypes.number,
        postmachinedGoal: PropTypes.number,
        bondedGoal: PropTypes.number,
        connectorizedGoal: PropTypes.number,
      })),
      amount: PropTypes.string,
    })),
  }),
  isEditing: PropTypes.bool,
};
