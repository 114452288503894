/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import {
  Header,
  Table,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import UserEmail from '../../components/UserEmail';

function Changelog({ travelerId }) {
  const { data, status } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'travelersChangelog'),
      where('traveler', '==', travelerId),
    ),
    { idField: 'id' },
  );

  const items = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((change) => {
      let user;
      const { before, after, timestamp } = change;
      if (after?._updatedBy) {
        user = after._updatedBy.uid;
      }

      const changes = ([
        { key: 'project', label: 'Project', format: (item) => item?.project },
        { key: 'tool', label: 'Tool', format: (item) => item?.tool },
        { key: 'moldRunId', label: 'Mold Run ID', format: (item) => (item?.moldRunId || []).map((run) => `${run.project} - ${run.runID}`).join(', ') },
        { key: 'moldedPartInspected', label: 'Molded Part Inspection', format: (item) => item?.moldedPartInspected },
        { key: 'bondRunId', label: 'Bond Run ID', format: (item) => (item?.bondRunId || []).map((run) => `${run.project} - ${run.runID}`).join(', ') },
        { key: 'bondedPartInspected', label: 'Bonded Part Inpsection', format: (item) => item?.bondedPartInspected },
        { key: 'notes', label: 'Additional Notes', format: (item) => item?.notes },
        { key: 'moldedNotes', label: 'Molded Notes', format: (item) => item?.moldedNotes },
        { key: 'secondaryNotes', label: 'Secondary Notes', format: (item) => item?.secondaryNotes },
        { key: 'bondedNotes', label: 'Bonded Notes', format: (item) => item?.bondedNotes },
        { key: 'secondaryInspected', label: 'Secondary Inspection', format: (item) => item?.secondaryInspected },
        { key: 'componentsWeldedInspected', label: 'Components Welded Inspection', format: (item) => item?.componentsWeldedInspected },
        { key: 'finalInspection', label: 'Final Inspection', format: (item) => item?.finalInspection },
        { key: 'shipped', label: 'Shipped', format: (item) => (item?.shipped ? 'Checked' : 'Unchecked') },
      ]).map(({ key, label, format }) => {
        if (before?.[key] || after?.[key]) {
          return {
            label,
            key,
            before: before?.[key] !== undefined ? format(before) : null,
            after: after?.[key] !== undefined ? format(after) : null,
          };
        }
        return null;
      }).filter(Boolean);
      return {
        user,
        timestamp,
        changes,
      };
    }).sort((a, b) => b.timestamp - a.timestamp);
  }, [data]);

  return (
    <Table
      header={
        <Header>History</Header>
      }
      items={items}
      columnDefinitions={[
        {
          id: 'user',
          header: 'User',
          cell: (item) => <UserEmail userID={item.user} backupEmail="System user" />,
        },
        {
          id: 'timestamp',
          header: 'Timestamp',
          cell: (item) => item.timestamp.toDate().toLocaleString(),
        },
        {
          id: 'changes',
          header: 'Changes',
          cell: (item) => (
            <>
              {item.changes.map(({
                key, label, before, after,
              }) => (
                <p key={key}>
                  <strong>{label}</strong>
                  :
                  {' '}
                  <code style={{ backgroundColor: 'lightpink', color: 'red' }}>{before || '█'}</code>
                  {' '}
                  →
                  {' '}
                  <code style={{ backgroundColor: 'lightgreen', color: 'green' }}>{after || '█'}</code>
                </p>
              ))}
            </>
          ),
        },
      ]}
      loading={status === 'loading'}
      loadingText="Loading changelog..."
    />
  );
}

Changelog.propTypes = {
  travelerId: PropTypes.string.isRequired,
};

export default Changelog;
