import {
  Alert,
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import {
  useProjectStatus,
  useShipDate,
} from './hooks';

// StatusDisplay only been used by ActiveOrderStatus
function StatusDisplay({ goal, statusData, title }) {
  return (
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">{title}</Box>
        <Box variant="awsui-value-large">
          {`${statusData.inspected}/${goal} Inspected`}
        </Box>
      </div>
      <div>
        <Box variant="awsui-key-label">To-be inspected</Box>
        <div>{statusData.toBeInspected}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
        <div>{statusData.rejectCosmetic}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Reject (Major)</Box>
        <div>{statusData.rejectMajor}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Tuning</Box>
        <div>{statusData.tuning}</div>
      </div>
    </SpaceBetween>
  );
}
StatusDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    inspected: PropTypes.number.isRequired,
    toBeInspected: PropTypes.number.isRequired,
    rejectCosmetic: PropTypes.number.isRequired,
    rejectMajor: PropTypes.number.isRequired,
    tuning: PropTypes.number.isRequired,
    unknown: PropTypes.number,
  }).isRequired,
};

function ActiveOrderStatus({
  customerName,
  projectNumber,
  shipDate,
  moldedGoal,
  postmachinedGoal,
  bondedGoal,
  connectorizedGoal,
  minDate,
  notes,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(projectNumber, minDate);
  const shipDateText = useShipDate(shipDate);

  if (queryStatus === 'loading') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      </Container>
    );
  }
  if (queryStatus === 'error') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="error">Error</StatusIndicator>
      </Container>
    );
  }
  return (
    <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
      <SpaceBetween direction="vertical" size="m">
        {notes ? (
          <Alert statusIconAriaLabel="Warning" type="warning">
            <TextContent>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: notes }} />
            </TextContent>
          </Alert>
        ) : null}
        <ColumnLayout
          columns={[
            moldedGoal, postmachinedGoal, bondedGoal, connectorizedGoal,
          ].filter((goal) => goal > 0).length}
          variant="text-grid"
          minColumnWidth={170}
        >
          {moldedGoal > 0 ? (
            <StatusDisplay
              title="Molded Parts"
              goal={moldedGoal}
              statusData={projectStatus.molded}
            />
          ) : null}
          {postmachinedGoal > 0 ? (
            <StatusDisplay
              title="Postmachined Parts"
              goal={postmachinedGoal}
              statusData={projectStatus.postmachined}
            />
          ) : null}
          {bondedGoal > 0 ? (
            <StatusDisplay
              title="Bonded Parts"
              goal={bondedGoal}
              statusData={projectStatus.bonded}
            />
          ) : null}
          {connectorizedGoal > 0 ? (
            <StatusDisplay
              title="Connectorized Parts"
              goal={connectorizedGoal}
              statusData={projectStatus.connectorized}
            />
          ) : null}
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
}

ActiveOrderStatus.propTypes = {
  customerName: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  moldedGoal: PropTypes.number,
  postmachinedGoal: PropTypes.number,
  bondedGoal: PropTypes.number,
  connectorizedGoal: PropTypes.number,
  minDate: PropTypes.string,
  notes: PropTypes.string,
};

ActiveOrderStatus.defaultProps = {
  moldedGoal: 0,
  postmachinedGoal: 0,
  bondedGoal: 0,
  connectorizedGoal: 0,
  minDate: undefined,
  notes: undefined,
};

export default ActiveOrderStatus;
