import 'chartjs-adapter-luxon';

import {
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';
import * as d3 from 'd3';
import React, {
  useContext,
  useMemo,
} from 'react';
import { Line } from 'react-chartjs-2';

import { ProcessDataContext } from './context';

ChartJS.register(
  ChartAnnotation,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
);

function ProcessGraph() {
  const {
    instronData,
    plcData,
    isPlcDataLoading,
    offset,
    setOffset,
  } = useContext(ProcessDataContext);

  const data = useMemo(() => {
    if (!plcData) {
      return { labels: [], datasets: [] };
    }

    const palette = [
      'red',
      'blue',
      ...d3.schemeCategory10,
      ...d3.schemeSet3,
    ];
    const tempKeys = [
      { key: 'UpperHFTS_ToolTemp', label: 'Top Temp' },
      { key: 'LowerHFTS_ToolTemp', label: 'Bottom Temp' },
      { key: 'UpperHFTS_HP1Temp', label: 'Top HP1' },
      { key: 'UpperHFTS_HP2Temp', label: 'Top HP2' },
      { key: 'UpperHFTS_HP3Temp', label: 'Top HP3' },
      { key: 'UpperHFTS_HP4Temp', label: 'Top HP4' },
      { key: 'UpperHFTS_HP5Temp', label: 'Top HP5' },
      { key: 'UpperHFTS_HP6Temp', label: 'Top HP6' },
      { key: 'LowerHFTS_HP1Temp', label: 'Bottom HP1' },
      { key: 'LowerHFTS_HP2Temp', label: 'Bottom HP2' },
      { key: 'LowerHFTS_HP3Temp', label: 'Bottom HP3' },
      { key: 'LowerHFTS_HP4Temp', label: 'Bottom HP4' },
      { key: 'LowerHFTS_HP5Temp', label: 'Bottom HP5' },
      { key: 'LowerHFTS_HP6Temp', label: 'Bottom HP6' },
    ];

    const temps = tempKeys.map(({ label, key }, i) => ({
      label,
      data: plcData.map((row) => ({ timestamp: row.timestamp, [label]: row[key] })),
      borderColor: palette[i],
      backgroundColor: palette[i],
      yAxisID: 'y',
      pointStyle: false,
      tension: 0.1,
      borderWidth: 2,
      hidden: !['Top Temp', 'Bottom Temp'].includes(label),
      tooltip: {
        callbacks: {
          label(context) {
            return `${label}: ${context.raw[label].toFixed(1)}°C`;
          },
        },
      },
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: label,
      },
    }));
    const forces = {
      label: 'Force',
      data: instronData?.timestamps?.map(
        (timestamp, i) => ({ timestamp, force: instronData.forces[i] }),
      ),
      borderColor: 'green',
      backgroundColor: 'green',
      yAxisID: 'y1',
      pointStyle: false,
      tension: 0.1,
      borderWidth: 2,
      tooltip: {
        callbacks: {
          label(context) {
            return `Force: ${context.raw.force.toFixed(1)} kN`;
          },
        },
      },
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: 'force',
      },
    };

    return {
      labels: plcData.map(({ timestamp }) => timestamp),
      datasets: forces?.data?.length > 0 ? [forces, ...temps] : temps,
    };
  }, [plcData, instronData]);

  const plugin = {
    id: 'verticalLine',
    defaults: {
      width: 1,
      color: '#FF4949',
      dash: [3, 3],
    },
    // eslint-disable-next-line no-param-reassign
    afterInit: (chart) => { chart.verticalLine = { x: 0 }; },
    afterEvent: (chart, args) => {
      const { inChartArea, event } = args;
      const { x } = event;

      // eslint-disable-next-line no-param-reassign
      chart.verticalLine = { x, draw: inChartArea };
      chart.draw();
    },
    beforeDatasetsDraw: (chart, _, opts) => {
      const { ctx, chartArea, verticalLine } = chart;
      const { top, bottom } = chartArea;
      const { x, draw } = verticalLine;
      if (!draw) return;

      ctx.save();

      ctx.beginPath();
      ctx.lineWidth = opts.width;
      ctx.strokeStyle = opts.color;
      ctx.setLineDash(opts.dash);
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, top);
      ctx.stroke();

      ctx.restore();
    },
  };

  const options = useMemo(() => {
    const opts = {
      interaction: {
        mode: 'x',
        intersect: false,
      },
      stacked: false,
      plugins: {
        verticalLine: {
          color: 'black',
        },
        tooltip: {
          animation: false,
          // Only show the first instance of each dataset in the tooltip.
          // TODO: Figure out if there's a more efficient way to do this.
          // The current complexity is O(n^2) because filter is called for
          // each element in the array.
          filter: (element, index, array) => {
            const firstIndexWithLabel = {};
            for (let i = 0; i < array.length; i += 1) {
              if (firstIndexWithLabel[array[i].dataset.label] === undefined) {
                firstIndexWithLabel[array[i].dataset.label] = i;
              }
            }
            return firstIndexWithLabel[element.dataset.label] === index;
          },
        },
        legend: {
          position: 'right',
        },
      },
      animation: {
        duration: 0,
      },
      scales: {
        y: {
          title: { text: 'Temperature (°C)', display: true },
          type: 'linear',
          display: true,
          position: 'left',
        },
        x: {
          title: { text: 'Time', display: true },
          type: 'time',
          time: {
            unit: 'minute',
            stepSize: 1,
            displayFormats: {
              minute: 'h:mma',
            },
          },
        },
      },
    };

    if (instronData?.timestamps.length > 0) {
      opts.scales.y1 = {
        title: { text: 'Force (kN)', display: true },
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      };
    }

    if (isPlcDataLoading) {
      opts.plugins.annotation = {
        annotations: {
          annotation: {
            type: 'box',
            backgroundColor: 'transparent',
            borderWidth: 0,
            label: {
              drawTime: 'afterDatasetsDraw',
              display: true,
              color: 'rgba(208, 208, 208, 0.5)',
              content: 'Loading...',
              font: {
                size: (ctx) => ctx.chart.chartArea.height / 4,
              },
              position: 'center',
            },
          },
        },
      };
    }

    return opts;
  }, [isPlcDataLoading]);

  return (
    <Container
      header={(
        <Header actions={(
          <SpaceBetween direction="horizontal">
            <FormField label="Process window (in minutes)">
              <Input
                onChange={({ detail }) => {
                  setOffset(parseInt(detail.value, 10));
                }}
                value={offset}
                type="number"
                inputMode="numeric"
              />
            </FormField>
          </SpaceBetween>
        )}
        >
          Process Graph
        </Header>
      )}
    >
      <Line options={options} data={data} plugins={[plugin]} />
    </Container>
  );
}

export default ProcessGraph;
