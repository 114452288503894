import {
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useFirestoreDocData,
  useUser,
} from 'reactfire';

import Changelog from './changelog';
import ProcessMultiselect from './processMultiselect';

function TravelerPage() {
  const [error, setError] = useState(null);

  const { data: user } = useUser();
  const { travelerId } = useParams();
  const { data: traveler, status: travelerStatus, error: travelerError } = useFirestoreDocData(doc(getFirestore(), 'travelers', travelerId));
  const { data: schema, status: schemaStatus, error: schemaError } = useFirestoreDocData(doc(getFirestore(), '_rowy_', 'settings', 'schema', 'partTravelers'));

  const [moldedPartInspectedOptions, moldedPartInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.moldedPartInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);
  const [bondedPartInspectedOptions, bondedPartInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.bondedPartInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);
  const [secondaryInspectedOptions, secondaryInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.secondaryInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);
  const [componentsWeldedInspectedOptions, componentsWeldedInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.componentsWeldedInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);
  const [finalInspectionOptions, finalInspectionOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.finalInspection?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);

  const initialValues = useMemo(() => {
    if (!traveler || travelerStatus !== 'success') {
      return {};
    }
    return {
      ...traveler,
      moldRunId: traveler.moldRunId,
      bondRunId: traveler.bondRunId,
      moldedPartInspected: traveler.moldedPartInspected ? {
        value: traveler.moldedPartInspected,
        label: traveler.moldedPartInspected,
      } : null,
      secondaryInspected: traveler.secondaryInspected ? {
        value: traveler.secondaryInspected,
        label: traveler.secondaryInspected,
      } : traveler.secondariesMachined && {
        value: 'Passed',
        label: 'Passed',
      },
      bondedPartInspected: traveler.bondedPartInspected ? {
        value: traveler.bondedPartInspected,
        label: traveler.bondedPartInspected,
      } : null,
      componentsWeldedInspected: traveler.componentsWeldedInspected ? {
        value: traveler.componentsWeldedInspected,
        label: traveler.componentsWeldedInspected,
      } : traveler.componentsWelded && {
        value: 'Passed',
        label: 'Passed',
      },
      finalInspection: traveler.finalInspection ? {
        value: traveler.finalInspection,
        label: traveler.finalInspection,
      } : traveler.passedFinalInspection && {
        value: 'Passed',
        label: 'Passed',
      },
    };
  }, [traveler, travelerStatus]);

  if (travelerStatus === 'loading') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                )}
      >
        <Container>
          {' '}
          <Spinner />
        </Container>
      </ContentLayout>
    );
  }

  if (travelerStatus === 'error') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Error loading traveler',
              content: travelerError,
              type: 'error',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  if (!traveler) {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            {`Edit Traveler ${travelerId}`}
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Traveler not found',
              content: `No traveler found with ID ${travelerId}. If you have a traveler sticker with this id, it might mean the traveler hasn't been used yet. Try starting a forming run with this travler!`,
              type: 'warning',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  return (
    <SpaceBetween direction="vertical" size="l">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setError(null);
          try {
            await updateDoc(doc(getFirestore(), 'travelers', travelerId), {
              project: values.project || '',
              tool: values.tool || '',
              moldRunId: values.moldRunId,
              moldedPartInspected: values.moldedPartInspected?.value || 'Not inspected',
              moldedNotes: values.moldedNotes || '',
              secondaryNotes: values.secondaryNotes || '',
              secondaryInspected: values.secondaryInspected?.value || 'Not inspected',
              bondRunId: values.bondRunId,
              bondedPartInspected: values.bondedPartInspected?.value || 'Not inspected',
              bondedNotes: values.bondedNotes || '',
              componentsWeldedInspected: values.componentsWeldedInspected?.value || 'Not inspected',
              componentsWeldedNotes: values.componentsWeldedNotes || '',
              finalInspection: values.finalInspection?.value || 'Not inspected',
              shipped: values.shipped || false,
              notes: values.notes || '',
              _updatedBy: {
                email: user.email,
                timestamp: serverTimestamp(),
                uid: user.uid,
              },
            });
          } catch (e) {
            setError(e.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit, setFieldValue, values, isSubmitting, dirty, errors,
        }) => (
          <ContentLayout
            header={(
              <Header
                variant="h2"
                actions={(
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!dirty}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              >
                Edit Traveler
              </Header>
            )}
          >
            <SpaceBetween direction="vertical" size="l">
              {error ? (
                <Flashbar
                  items={[{
                    header: 'Error saving traveler',
                    content: error,
                    type: 'error',
                  }]}
                />
              ) : null}

              {/* Project */}
              <Container>
                <Header variant="h3">
                  {`Traveler ID: ${travelerId}`}
                </Header>
                <ColumnLayout columns={2}>
                  <FormField
                    label="Project"
                    errorText={errors.project}
                    stretch
                  >
                    <Input
                      value={values.project}
                      onChange={({ detail }) => setFieldValue('project', detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Tool"
                    errorText={errors.tool}
                    stretch
                  >
                    <Input
                      value={values.tool}
                      onChange={({ detail }) => setFieldValue('tool', detail.value)}
                    />
                  </FormField>
                </ColumnLayout>
              </Container>

              {/* Molding */}
              <Container>
                <Header variant="h3">
                  Molding
                </Header>
                <SpaceBetween size="xs">
                  <ColumnLayout columns={2}>
                    <FormField
                      label="Run ID"
                      errorText={errors.moldRunId}
                      stretch
                    >
                      <ProcessMultiselect
                        projectId={values.project}
                        process="forming"
                        selectedOptions={values.moldRunId}
                        setValue={(moldRunId) => setFieldValue('moldRunId', moldRunId)}
                      />
                    </FormField>
                    <FormField
                      label="Inspection"
                      errorText={errors.moldedPartInspected}
                      stretch
                    >
                      <Select
                        selectedOption={values.moldedPartInspected}
                        onChange={({ detail }) => setFieldValue('moldedPartInspected', detail.selectedOption)}
                        statusType={moldedPartInspectedOptionsError ? 'error' : schemaStatus}
                        recoveryText={moldedPartInspectedOptionsError}
                        options={moldedPartInspectedOptions}
                      />
                    </FormField>
                  </ColumnLayout>
                  <FormField
                    label="Molding notes"
                    errorText={errors.moldedNotes}
                    stretch
                  >
                    <Textarea
                      value={values.moldedNotes}
                      onChange={({ detail }) => setFieldValue('moldedNotes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Secondaries */}
              <Container>
                <Header variant="h3">
                  Secondaries
                </Header>
                <SpaceBetween size="xs">
                  <FormField
                    label="Inspection"
                    errorText={errors.secondaryInspected}
                    stretch
                  >
                    <Select
                      selectedOption={values.secondaryInspected}
                      onChange={({ detail }) => setFieldValue('secondaryInspected', detail.selectedOption)}
                      statusType={secondaryInspectedOptionsError ? 'error' : schemaStatus}
                      recoveryText={secondaryInspectedOptionsError}
                      options={secondaryInspectedOptions}
                    />
                  </FormField>
                  <FormField
                    label="Secondary notes"
                    errorText={errors.secondaryNotes}
                    stretch
                  >
                    <Textarea
                      value={values.secondaryNotes}
                      onChange={({ detail }) => setFieldValue('secondaryNotes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Bonding */}
              <Container>
                <Header variant="h3">
                  Bonding
                </Header>
                <SpaceBetween size="xs">
                  <ColumnLayout columns={2}>
                    <FormField
                      label="Run ID"
                      errorText={errors.bondRunId}
                      stretch
                    >
                      <ProcessMultiselect
                        projectId={values.project}
                        process="bonding"
                        selectedOptions={values.bondRunId}
                        setValue={(bondRunId) => setFieldValue('bondRunId', bondRunId)}
                      />
                    </FormField>
                    <FormField
                      label="Inspection"
                      errorText={errors.bondedPartInspected}
                      stretch
                    >
                      <Select
                        selectedOption={values.bondedPartInspected}
                        onChange={({ detail }) => setFieldValue('bondedPartInspected', detail.selectedOption)}
                        statusType={bondedPartInspectedOptionsError ? 'error' : schemaStatus}
                        recoveryText={bondedPartInspectedOptionsError}
                        options={bondedPartInspectedOptions}
                      />
                    </FormField>
                  </ColumnLayout>
                  <FormField
                    label="Bonding notes"
                    errorText={errors.bondedNotes}
                    stretch
                  >
                    <Textarea
                      value={values.bondedNotes}
                      onChange={({ detail }) => setFieldValue('bondedNotes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Component Welding */}
              <Container>
                <Header variant="h3">
                  Component Welding
                </Header>
                <SpaceBetween size="xs">
                  <FormField
                    label="Inspection"
                    errorText={errors.componentsWeldedInspected}
                    stretch
                  >
                    <Select
                      selectedOption={values.componentsWeldedInspected}
                      onChange={({ detail }) => setFieldValue('componentsWeldedInspected', detail.selectedOption)}
                      statusType={componentsWeldedInspectedOptionsError ? 'error' : schemaStatus}
                      recoveryText={componentsWeldedInspectedOptionsError}
                      options={componentsWeldedInspectedOptions}
                    />
                  </FormField>
                  <FormField
                    label="Welding notes"
                    errorText={errors.componentsWeldedNotes}
                    stretch
                  >
                    <Textarea
                      value={values.componentsWeldedNotes}
                      onChange={({ detail }) => setFieldValue('componentsWeldedNotes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Final Inspection */}
              <Container>
                <Header variant="h3">
                  Final Inspection
                </Header>
                <SpaceBetween size="xs">
                  <FormField
                    label="Inspection"
                    errorText={errors.finalInspection}
                    stretch
                  >
                    <Select
                      selectedOption={values.finalInspection}
                      onChange={({ detail }) => setFieldValue('finalInspection', detail.selectedOption)}
                      statusType={finalInspectionOptionsError ? 'error' : schemaStatus}
                      recoveryText={finalInspectionOptionsError}
                      options={finalInspectionOptions}
                    />
                  </FormField>
                  <FormField
                    label="Additional notes"
                    errorText={errors.notes}
                    stretch
                  >
                    <Textarea
                      value={values.notes}
                      onChange={({ detail }) => setFieldValue('notes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Shipped */}
              <Container>
                <Header variant="h3">
                  Shipped
                </Header>
                <FormField
                  errorText={errors.shipped}
                  stretch
                >
                  <Checkbox
                    onChange={({ detail }) => {
                      setFieldValue('shipped', detail.checked);
                    }}
                    checked={values.shipped || false}
                  >
                    Shipped
                  </Checkbox>
                </FormField>
              </Container>
            </SpaceBetween>
          </ContentLayout>
        )}
      </Formik>
      <Changelog travelerId={travelerId} />
    </SpaceBetween>
  );
}

export default TravelerPage;
