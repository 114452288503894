import './lineItemsTable.css';

import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Textarea,
} from '@cloudscape-design/components';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

export default function LineItemsTable({ shipmentIndex, defaultLineItem }) {
  const {
    values: { shipments }, setFieldValue, errors,
  } = useFormikContext();
  const { lineItems: currentLineItems } = shipments[shipmentIndex];

  return (
    <Container
      disableContentPaddings
      header={(
        <Header>
          Line Items
        </Header>
      )}
    >
      <div className="table_container">
        <table className="line_item_table">
          <thead>
            <tr>
              <th>Project ID</th>
              <th>Quantity</th>
              <th>Bonding</th>
              <th>Notes</th>
              <th>Molded Goal</th>
              <th>Postmachined Goal</th>
              <th>Bonded Goal</th>
              <th>Connectorized Goal</th>
            </tr>
          </thead>
          <tbody>
            {currentLineItems.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={idx}>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.projectId}
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].projectId`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.projectId}
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].projectId`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.quantity}
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].quantity`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.quantity}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].quantity`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.bonding}
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].bonding`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.bonding}
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].bonding`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.notes}
                  >
                    <Textarea
                      rows={1}
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].notes`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.notes}
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].notes`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.moldedGoal}
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].moldedGoal`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.moldedGoal}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].moldedGoal`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={
                      errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.postmachinedGoal
                    }
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].postmachinedGoal`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.postmachinedGoal}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].postmachinedGoal`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.bondedGoal}
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].bondedGoal`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.bondedGoal}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].bondedGoal`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={
                      errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.connectorizedGoal
                    }
                  >
                    <Input
                      name={`shipments[${shipmentIndex}].lineItems[${idx}].connectorizedGoal`}
                      value={shipments[shipmentIndex]?.lineItems[idx]?.connectorizedGoal}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].connectorizedGoal`, detail.value); }}
                    />
                  </FormField>
                </td>
                {idx > 0 && (
                  <td>
                    <Button
                      formAction="none"
                      iconName="close"
                      variant="icon"
                      onClick={() => {
                        const updatedLineItems = currentLineItems.filter((_, i) => i !== idx);
                        setFieldValue(`shipments[${shipmentIndex}].lineItems`, updatedLineItems);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Box padding={{ top: 'm' }}>
          <Button
            formAction="none"
            onClick={() => {
              setFieldValue(`shipments[${shipmentIndex}].lineItems`, [
                ...currentLineItems,
                defaultLineItem,
              ]);
            }}
          >
            Add new line item
          </Button>
        </Box>
      </div>
    </Container>
  );
}
LineItemsTable.propTypes = {
  shipmentIndex: PropTypes.number.isRequired,
  defaultLineItem: PropTypes.shape({
    projectId: PropTypes.string,
    quantity: PropTypes.string,
    bonding: PropTypes.string,
    notes: PropTypes.string,
    moldedGoal: PropTypes.string,
    postmachinedGoal: PropTypes.string,
    bondedGoal: PropTypes.string,
    connectorizedGoal: PropTypes.string,
  }).isRequired,
};
