/* eslint-disable react/no-unstable-nested-components */
import {
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Select,
  StatusIndicator,
  Table,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
} from 'firebase/firestore';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useFirestoreDocData } from 'reactfire';
import * as Yup from 'yup';

import { usePricingSchema } from './pricingSchemaProvider';

function SelectAttribute({ item, currentValue, setValue }) {
  const selectOptions = useMemo(() => {
    const { attribute: currentAttribute } = item;
    if (!currentAttribute) {
      return [];
    }
    return [
      ...currentAttribute.options.map(({ title }) => ({
        label: title,
        value: title,
      })),
      {
        label: 'Custom',
        value: 'custom',
      },
    ];
  }, [item, currentValue]);
  const selectValue = selectOptions.find(
    ({ value }) => value === (currentValue?.value ?? item.value),
  );
  return (
    <Select
      autoFocus
      selectedOption={selectValue}
      placeholder="Please select"
      onChange={({ detail }) => {
        setValue(detail.selectedOption);
      }}
      options={selectOptions}
    />
  );
}

SelectAttribute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  currentValue: PropTypes.shape({
    value: PropTypes.string,
  }),
  setValue: PropTypes.func.isRequired,
};

SelectAttribute.defaultProps = {
  currentValue: null,
};

function NumberAttribute({ item, currentValue, setValue }) {
  const { pricingType, quantity } = useMemo(() => {
    if (currentValue) {
      return currentValue;
    }
    if (item.value) {
      return item.value;
    }
    return { pricingType: 'default', quantity: 0 };
  }, [currentValue, item]);
  const options = [{
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Custom',
    value: 'custom',
  }];
  const selectedOption = useMemo(
    () => options.find(({ value }) => value === pricingType ?? item.value.pricingType),
    [currentValue, item],
  );

  return (
    <Grid
      gridDefinition={pricingType === 'default'
        ? [{ colspan: 8 }, { colspan: 4 }]
        : [{ colspan: 12 }]}
    >
      <Select
        autoFocus
        selectedOption={selectedOption}
        onChange={({ detail }) => {
          setValue({ pricingType: detail.selectedOption.value, quantity: 0 });
        }}
        options={options}
      />
      { pricingType === 'default' ? (
        <Input
          type="number"
          inputMode="numeric"
          value={quantity}
          onChange={({ detail }) => {
            setValue({ pricingType: 'default', quantity: detail.value });
          }}
        />
      ) : null}
    </Grid>
  );
}

NumberAttribute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  currentValue: PropTypes.shape({
    value: PropTypes.string,
  }),
  setValue: PropTypes.func.isRequired,
};

NumberAttribute.defaultProps = {
  currentValue: null,
};

function EditPricingTable({ attributesVersion }) {
  const { updatePricingSchema } = usePricingSchema();

  const {
    values, setFieldValue, setFieldTouched, errors,
  } = useFormikContext();
  const { attributes: currentAttributeValues } = values;

  const { data: quoteAttributes, status: attributesStatus } = useFirestoreDocData(
    doc(getFirestore(), 'quoteAttributes', attributesVersion),
  );

  const { status, rows, schema } = useMemo(() => {
    if (attributesStatus !== 'success') {
      return { status: 'loading', rows: [], schema: Yup.object() };
    }
    const { attributes } = quoteAttributes || {};
    if (!attributes) {
      return { status: 'error', rows: [], schema: Yup.object() };
    }

    return {
      status: 'success',
      rows: attributes.map((attribute, index) => {
        const {
          value, setup, unit, notes,
        } = currentAttributeValues[attribute.title] || {};
        return {
          attribute,
          value,
          setup,
          unit,
          index,
          notes,
        };
      }),
      schema: Yup.object().shape(attributes.reduce((acc, attribute) => ({
        ...acc,
        [attribute.title]: {
          select: Yup
            .object()
            .shape({ value: Yup.string().required('Selection required') })
            .required()
            .default({ value: '' }),
          number: Yup.object().shape({
            value: Yup.object().shape({
              pricingType: Yup.string().nullable().oneOf(['default', 'custom']).required('Selection required'),
              quantity: Yup.number().nullable().when(`attributes[${attribute.title}].value.pricingType`, {
                is: 'default',
                then: (s) => s.required('Quantity is required'),
              }),
            })
              .required()
              .default({}),
          }),
        }[attribute.type],
      }), {})).nullable().default({}),
    };
  }, [attributesStatus, currentAttributeValues, quoteAttributes]);

  useEffect(() => { updatePricingSchema('attributes', schema); }, [schema, updatePricingSchema]);

  useEffect(() => {
    setFieldValue(
      'calculatedValues.uncappedSetupPrice',
      rows.reduce((acc, { attribute, setup }) => (acc
          + (!attribute.cappingOnly
            ? parseFloat(setup || 0)
            : 0)
      ), 0) * values.multiplier,
    );
    setFieldValue(
      'calculatedValues.uncappedUnitPrice',
      rows.reduce((acc, { attribute, unit }) => (acc
          + (!attribute.cappingOnly && !attribute.unscaled
            ? parseFloat(unit || 0)
            : 0)
      ), 0) * values.multiplier,
    );
    setFieldValue(
      'calculatedValues.uncappedUnscaledUnitPrice',
      rows.reduce((acc, { attribute, unit }) => (acc
          + (!attribute.cappingOnly && attribute.unscaled
            ? parseFloat(unit || 0)
            : 0)
      ), 0) * values.multiplier,
    );

    setFieldValue(
      'calculatedValues.cappedSetupPrice',
      rows.reduce((acc, { setup }) => (
        acc + parseFloat(setup || 0)
      ), 0) * values.multiplier,
    );
    setFieldValue(
      'calculatedValues.cappedUnitPrice',
      rows.reduce((acc, { attribute, unit }) => (acc
          + (!attribute.unscaled
            ? parseFloat(unit || 0)
            : 0)
      ), 0) * values.multiplier,
    );
    setFieldValue(
      'calculatedValues.cappedUnscaledUnitPrice',
      rows.reduce((acc, { attribute, unit }) => (acc
          + (attribute.unscaled
            ? parseFloat(unit || 0)
            : 0)
      ), 0) * values.multiplier,
    );
    setFieldTouched('calculatedValues', true);
  }, [rows, values.multiplier]);

  const summaryRows = useMemo(() => {
    const { calculatedValues } = values;
    if (!calculatedValues) {
      return [];
    }

    const {
      uncappedSetupPrice,
      uncappedUnitPrice,
      cappedSetupPrice,
      cappedUnitPrice,
      uncappedUnscaledUnitPrice,
      cappedUnscaledUnitPrice,
    } = calculatedValues;
    return [{
      attribute: {
        title: <span style={{ fontWeight: 800 }}>Uncapped Total</span>,
        type: 'summary',
      },
      value: '',
      setup: uncappedSetupPrice,
      unit: uncappedUnitPrice + uncappedUnscaledUnitPrice,
    },
    {
      attribute: {
        title: <span style={{ fontWeight: 800 }}>Capped Total</span>,
        type: 'summary',
      },
      value: '',
      setup: cappedSetupPrice,
      unit: cappedUnitPrice + cappedUnscaledUnitPrice,
    }];
  }, [values]);

  if (status === 'error') {
    return (
      <Container header={<Header variant="h2">Pricing Info</Header>} fitHeight>
        <div>There was an error loading the pricing information.</div>
      </Container>
    );
  }

  return (
    <Container>
      <Grid gridDefinition={[{ colspan: 12 }, { colspan: 2 }, { colspan: 10 }]}>
        <Table
          variant="embedded"
          loading={status === 'loading'}
          loadingText="Loading pricing information..."
          items={[...rows, ...summaryRows]}
          submitEdit={(row, column, newValue) => {
            switch (column.id) {
              case 'value': {
                switch (row.attribute.type) {
                  case 'select': {
                  // Update the value
                    setFieldValue(`attributes[${row.attribute.title}].value`, newValue.value);

                    // Update the setup and unit prices, if available
                    const option = row.attribute.options.find(
                      ({ title }) => title === newValue.value,
                    );
                    if (!option) {
                      break;
                    }

                    // If there is pricing for the selection option, update appropriately
                    setFieldValue(`attributes[${row.attribute.title}].setup`, option.setup);
                    setFieldValue(`attributes[${row.attribute.title}].unit`, option.unit);
                    break;
                  }
                  case 'number': {
                    setFieldValue(`attributes[${row.attribute.title}].value`, newValue);
                    setFieldTouched(`attributes[${row.attribute.title}]`, true);
                    if (newValue.pricingType !== 'default') {
                      break;
                    }

                    // If pricing type is default, update the setup and unit prices accordingly
                    const { quantity } = newValue;
                    const { unit, setup } = row.attribute.value;

                    setFieldValue(`attributes[${row.attribute.title}].setup`, setup);
                    setFieldValue(`attributes[${row.attribute.title}].unit`, unit * quantity);
                    break;
                  }
                  default: {
                    throw new Error(`Unknown attribute type ${row.attribute.type}`);
                  }
                }
                break;
              }
              case 'setup': {
                setFieldValue(`attributes[${row.attribute.title}].setup`, newValue);
                break;
              }
              case 'unit': {
                setFieldValue(`attributes[${row.attribute.title}].unit`, newValue);
                break;
              }
              case 'notes': {
                setFieldValue(`attributes[${row.attribute.title}].notes`, newValue);
                break;
              }
              default: {
                throw new Error('Unknown column');
              }
            }
            setFieldTouched(`attributes[${row.attribute.title}]`, true);
          }}
          columnDefinitions={[
            {
              id: 'title',
              header: 'Attribute',
              cell: (row) => row.attribute.title,
              width: '20%',
            },
            {
              id: 'value',
              header: 'Value',
              cell: (row) => {
                switch (row.attribute.type) {
                  case 'select': {
                    if ((errors?.attributes || {})[row.attribute.title]?.value) {
                      return <StatusIndicator type="warning">{errors.attributes[row.attribute.title].value}</StatusIndicator>;
                    }
                    if (!row.value) {
                      return 'Please select';
                    }
                    if (row.value === 'custom') {
                      return 'Custom';
                    }
                    return row.value;
                  }
                  case 'number': {
                    if ((errors?.attributes || {})[row.attribute.title]?.value?.pricingType) {
                      return <StatusIndicator type="warning">{errors.attributes[row.attribute.title].value.pricingType}</StatusIndicator>;
                    }
                    if (!row.value) {
                      return 'Please select';
                    }
                    switch (row.value.pricingType) {
                      case 'default': {
                        return `Quantity: ${row.value.quantity}`;
                      }
                      case 'custom': {
                        return 'Custom pricing';
                      }
                      default: {
                        throw new Error('Unknown pricing type');
                      }
                    }
                  }
                  case 'summary': {
                    return row.value;
                  }
                  default: {
                    throw new Error(`Unknown attribute type ${row.attribute.type}`);
                  }
                }
              },
              width: '25%',
              editConfig: {
                editingCell: (
                  item,
                  { currentValue, setValue },
                ) => {
                  switch (item.attribute.type) {
                    case 'select': {
                      return (
                        <SelectAttribute
                          item={item}
                          currentValue={currentValue}
                          setValue={setValue}
                        />
                      );
                    }
                    case 'number': {
                      return (
                        <NumberAttribute
                          item={item}
                          currentValue={currentValue}
                          setValue={setValue}
                        />
                      );
                    }
                    default: {
                      throw new Error(`Unknown attribute type ${item.attribute.type}`);
                    }
                  }
                },
                disabledReason: (row) => {
                  if (row.attribute.type === 'summary') {
                    return 'Summary rows are not editable';
                  }
                  return undefined;
                },
              },
            },
            {
              id: 'setup',
              header: 'Setup cost',
              cell: (row) => (
                row.setup !== undefined
                  ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.setup)
                  : ''
              ),
              width: '15%',
              editConfig: {
                editingCell: (
                  item,
                  { currentValue, setValue },
                ) => (
                  <Input
                    autoFocus
                    type="number"
                    inputMode="numeric"
                    value={currentValue ?? item.setup}
                    onChange={({ detail }) => {
                      setValue(parseFloat(detail.value || '0'));
                    }}
                  />
                ),
                disabledReason: (row) => {
                  switch (row.attribute.type) {
                    case 'select': {
                      if (!row.value) {
                        return 'Select an attribute value first';
                      }
                      if (row.value !== 'custom') {
                        return 'Setup cost is only editable for custom pricing';
                      }
                      return undefined;
                    }
                    case 'number': {
                      if (!row.value) {
                        return 'Select an attribute value first';
                      }
                      if (row.value.pricingType !== 'custom') {
                        return 'Setup cost is only editable for custom pricing';
                      }
                      return undefined;
                    }
                    case 'summary': {
                      return 'Summary rows are not editable';
                    }
                    default: {
                      throw new Error(`Unknown attribute type ${row.attribute.type}`);
                    }
                  }
                },
              },
            },
            {
              id: 'unit',
              header: 'Base unit cost',
              cell: (row) => (
                row.unit !== undefined
                  ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.unit)
                  : ''),
              width: '15%',
              editConfig: {
                editingCell: (
                  item,
                  { currentValue, setValue },
                ) => (
                  <Input
                    autoFocus
                    type="number"
                    inputMode="numeric"
                    value={currentValue ?? item.unit}
                    onChange={({ detail }) => {
                      setValue(parseFloat(detail.value || '0'));
                    }}
                  />
                ),
                disabledReason: (row) => {
                  switch (row.attribute.type) {
                    case 'select': {
                      if (!row.value) {
                        return 'Select an attribute value first';
                      }
                      if (row.value !== 'custom') {
                        return 'Unit cost is only editable for custom pricing';
                      }
                      return undefined;
                    }
                    case 'number': {
                      if (!row.value) {
                        return 'Select an attribute value first';
                      }
                      if (row.value.pricingType !== 'custom') {
                        return 'Unit cost is only editable for custom pricing';
                      }
                      return undefined;
                    }
                    case 'summary': {
                      return 'Summary rows are not editable';
                    }
                    default: {
                      throw new Error(`Unknown attribute type ${row.attribute.type}`);
                    }
                  }
                },
              },
            },
            {
              id: 'notes',
              header: 'Notes',
              cell: (row) => row.notes,
              width: '25%',
              editConfig: {
                editingCell: (
                  item,
                  { currentValue, setValue },
                ) => (
                  <Input
                    autoFocus
                    value={currentValue ?? item.notes}
                    onChange={({ detail }) => { setValue(detail.value); }}
                  />
                ),
                disabledReason: (row) => {
                  if (row.attribute.type === 'summary') {
                    return 'Summary rows are not editable';
                  }
                  return undefined;
                },
              },
            },
          ]}
          resizableColumns
        />
        <FormField
          label="Pricing multiplier"
          description={'Changes the final pricing, but will only be visible in the "Uncapped Total" and "Capped Total" rows.'}
          stretch
        >
          <Input
            type="number"
            inputMode="numeric"
            onChange={({ detail }) => {
              setFieldValue('multiplier', detail.value ? parseFloat(detail.value) : '');
              setFieldTouched('multiplier', true);
            }}
            step={0.1}
            value={values.multiplier}
          />
        </FormField>
        <FormField label="Additional notes" stretch>
          <Textarea
            onChange={({ detail }) => {
              setFieldValue('notes', detail.value);
              setFieldTouched('notes', true);
            }}
            value={values.notes}
          />
        </FormField>
      </Grid>
    </Container>
  );
}

EditPricingTable.propTypes = {
  attributesVersion: PropTypes.string.isRequired,
};

export default EditPricingTable;
