import {
  Badge,
  Button,
  Table,
} from '@cloudscape-design/components';
import { Query } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestoreCollectionData } from 'reactfire';

import { useOrderDetail } from './orderDetailProvider';

function OrderList({ header, ordersQuery, variant }) {
  const { setOrderId } = useOrderDetail();
  const navigate = useNavigate();
  const { status: orderQueryStatus, data: orders } = useFirestoreCollectionData(
    ordersQuery,
    { idField: 'id', initialData: [] },
  );

  const orderRows = useMemo(() => orders.map((order) => ({
    ...order,
    nameButton: (
      <Button
        variant="inline-link"
        onClick={() => setOrderId(order.id)}
      >
        {order.name}
      </Button>
    ),
    statusBadge: (
      <Badge
        color={{
          'Not started': 'grey',
          'In progress': 'blue',
          Shipped: 'green',
          Archived: 'grey',
        }[order.status] || 'grey'}
      >
        {order.status}
      </Badge>
    ),
  })), [orders]);

  return (
    <Table
      header={header}
      variant={variant}
      loading={orderQueryStatus === 'loading'}
      items={orderRows}
      stickyHeader={variant === 'full-page'}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Order ID',
          cell: (item) => item.nameButton,
          minWidth: '120px',
        },
        {
          id: 'orderDate',
          header: 'Order Date',
          cell: (item) => item.orderDate,
          minWidth: '120px',
        },
        {
          id: 'customer',
          header: 'Customer',
          cell: (item) => item.customer,
          minWidth: '120px',
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => item.statusBadge,
          minWidth: '120px',
        },
        ...(variant === 'full-page' ? [
          {
            id: 'actions',
            header: 'Actions',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (item) => <Button onClick={() => navigate(`/orders/update/${item.id}`)}>Edit</Button>,
          },
        ] : []),
      ]}
    />
  );
}

OrderList.propTypes = {
  header: PropTypes.node,
  variant: PropTypes.string,
  ordersQuery: PropTypes.instanceOf(Query).isRequired,
};

OrderList.defaultProps = {
  variant: 'container',
  header: null,
};

export default OrderList;
